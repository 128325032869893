<template>
  <div class="personinfo">
    <!-- <h1>个人信息</h1> -->
    <!-- 拖拽功能必须绑定row-key唯一 -->
    <el-table ref="dragTable" :data="tableData" style="width: 100%" row-key="id">
      <el-table-column prop="name" label="姓名" width="180"></el-table-column>
      <el-table-column prop="address" label="地址"></el-table-column>
      <el-table-column label="操作" align="center" width="220px">
        <template>
          <el-tooltip effect="light" content="点击拖动排序" placement="right">
            <i class="el-icon-sort" style="font-size: 20px; cursor: pointer" @click="setRowSort"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
   
 <script>
// 引用第三方拖拽插件
import Sortable from "sortablejs";
export default {
  name: "index",
  data() {
    return {
      tableData: [
        {
          id: 1,
          name: "一",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 2,

          name: "二",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          id: 3,

          name: "三",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          id: 4,

          name: "四",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      newList: [],
    };
  },
  mounted() {
    //这里我是在mounted中调用了拖拽方法，一般获取到表格数据后nextTick中就可以调用拖拽方法
    this.setRowSort();
  },
  methods: {
    setRowSort() {
      this.$nextTick(() => {
        this.drop = Sortable.create(
          document.querySelector(".el-table .el-table__body-wrapper tbody"),
          {
            //这里指操作的图标，点击图标可以进行拖拽，若注释掉，则点击此行都可以进行拖拽
            handle: ".el-icon-sort",
            // onEnd(元素拖拽结束)
            onEnd: ({ newIndex, oldIndex }) => {
              this.tableData.splice(
                newIndex,
                0,
                this.tableData.splice(oldIndex, 1)[0]
              );
              var newArray = this.tableData.slice(0);
              this.tableData = [];
              this.$nextTick(function () {
                this.tableData = newArray;
              });
            },
          }
        );
      });
    },
  },
};
</script>
  <style scoped></style>